import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { isTokenExpired, logout } from "utils/authUtils";
import { enqueueSnackbar } from "notistack";
import PeopleIcon from '@mui/icons-material/People';
import DomainIcon from '@mui/icons-material/Domain';
import WeekendIcon from '@mui/icons-material/Weekend';
import EventIcon from '@mui/icons-material/Event';
import axios from "axios";
import { Watermark } from 'antd';

import Chamber_SaaS_Logo_Transparent from '../../assets/Chamber_SaaS_Logo_Transparent.png';
import Grey_dark from '../../assets/Chamber_SaaS_Logo_Grey_Dark.png';
import Grey_light from '../../assets/Chamber_SaaS_Logo_Grey_Light.png';

function Dashboard() {
  const [dashboardData, setDashboardData] = useState({});

  const authToken = localStorage.getItem("token");

  const fetchDashboard = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASEURL}/dashboard/dashboarddetails`, {"role": "superadmin"}, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if(res.data.success) {
        setDashboardData(res.data.data);
      } else {
        enqueueSnackbar("Unexpected response format", { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching Dashboard data:", error);
      enqueueSnackbar("Error fetching Dashboard Data", { variant: "error" });
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath === '/dashboard') {
      fetchDashboard();
    }
  }, [authToken]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Watermark
        // height={256}
        // width={256}
        // height={512}
        // width={512}
        // image={Chamber_SaaS_Logo_Transparent}
        // zIndex={-1}
        //   gap={[1500, 1500]}
          // style={{
            //  display:'flex',
            //  justifyContent:'center',
            //  alignItems:'center',
            //  height:'100vh',
            //  position:'relative',
            //  left: '50%'
          //  }}
      // style={{opacity:"0.5"}}
      > */}
        <MDBox py={3} style={{
          height: "82vh",
          // backgroundImage: `url(${Chamber_SaaS_Logo_Transparent})`,
          //   backgroundSize: 'contain',
          //   backgroundPosition: 'center',
          //   backgroundRepeat: 'no-repeat',
          //   opacity: 0.7,
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            // position: 'relative'
        }}>
           <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${Chamber_SaaS_Logo_Transparent})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.7, // Set opacity for background image
            zIndex: -1 // Ensure background is behind the content
          }}
        />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="#6c5ce7"
                  // color="info"
                  icon={<PeopleIcon />}
                  title="Number Of Chambers"
                  count={dashboardData.chambers_count}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      {/* </Watermark> */}
    </DashboardLayout>

  );
}

export default Dashboard;
