import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, IconButton, Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DataTable from '../../examples/Tables/DataTable';
import { enqueueSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import MDBadge from "../../components/MDBadge";
import { checkAuth } from '../../utils/authUtils';

import { Watermark } from 'antd';

import Chamber_SaaS_Logo_Transparent from '../../assets/Chamber_SaaS_Logo_Transparent.png';
import Grey_dark from '../../assets/Chamber_SaaS_Logo_Grey_Dark.png';
import Grey_light from '../../assets/Chamber_SaaS_Logo_Grey_Light.png';

interface Template {
  id: string;
  name: string;
  components: {
    type: 'HEADER' | 'BODY' | 'FOOTER';
    text: string;
  }[];
  status: string;
  category: string;
}

interface Row {
  row: {
    original: {
      status: string;
    };
  };
}

const Templates: React.FC = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [templateDialogOpen, setTemplateDialogOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [headerText, setHeaderText] = useState<string>('');
  const [bodyText, setBodyText] = useState<string>('');
  const [footerText, setFooterText] = useState<string>('');
  const authToken = localStorage.getItem("token");

  const handleTemplateClick = (template: Template) => {
    setSelectedTemplate(template);
    const header = template.components.find(c => c.type === 'HEADER')?.text || '';
    const body = template.components.find(c => c.type === 'BODY')?.text || '';
    const footer = template.components.find(c => c.type === 'FOOTER')?.text || '';
    setHeaderText(header);
    setBodyText(body);
    setFooterText(footer);
    setTemplateDialogOpen(true);
  };

  const handleDialogClose = () => {
    setTemplateDialogOpen(false);
    setHeaderText('');
    setBodyText('');
    setFooterText('');
  };

  const generateExample = (text: string, type: string) => {
    // Determine the number of placeholders in the text
    const placeholderCount = (text.match(/{{\d+}}/g) || []).length;
    if (placeholderCount === 0) {
      return undefined; // Return undefined if no example is needed
    }
    // Generate example data based on the number of placeholders
    const exampleData: any = {};

    if (type === "HEADER" && placeholderCount > 0) {
      exampleData.header_text = Array.from({ length: placeholderCount }, (_, i) => `Sample Header ${i + 1}`);
    } else if (type === "BODY" && placeholderCount > 0) {
      exampleData.body_text = [Array.from({ length: placeholderCount }, (_, i) => `Sample Body ${i + 1}`)];
    }

    return exampleData;
  };

  const handleDialogSubmit = async () => {
    if (selectedTemplate) {
      try {
        // Construct the components array dynamically
        const components: any[] = [];

        if (headerText.trim() !== "") {
          components.push({
            type: "HEADER",
            format: "TEXT",
            text: headerText,
            ...(generateExample(headerText, "HEADER") && { example: generateExample(headerText, "HEADER") }), // Conditionally add example if not undefined
          });
        }

        if (bodyText.trim() !== "") {
          components.push({
            type: "BODY",
            text: bodyText,
            ...(generateExample(bodyText, "BODY") && { example: generateExample(bodyText, "BODY") }), // Conditionally add example if not undefined
          });
        }

        if (footerText.trim() !== "") {
          components.push({
            type: "FOOTER",
            text: footerText,
          });
        }

        const payload = {
          category: selectedTemplate.category,
          components,
        };
        const response = await axios.post<{ success: boolean; data: Template[] }>(
          `${process.env.REACT_APP_FB_BASEURL}${selectedTemplate.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_FB_AUTH_TOKEN}`,
            },
          }
        );
        if (response.data) {
          enqueueSnackbar('Template updated successfully.', { variant: 'success' });
          window.location.reload();
        }
      } catch (error: any) {
        let errorMessage = 'Failed to update the template.';
        if (error.response && error.response.data && error.response.data.error) {
          const apiError = error.response.data.error;
          errorMessage = apiError.error_user_msg || apiError.message || errorMessage;
        }
        
        console.error('Error updating template:', error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
      handleDialogClose();
    }
  };

  const fetchAllTemplates = async () => {
    setLoading(true);

    if (!checkAuth()) return;

    try {
      const response = await axios.get<{ success: boolean; data: Template[] }>(
        `${process.env.REACT_APP_FB_BASEURL}/${process.env.REACT_APP_FB_BUSINESS_ID}/message_templates`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_FB_AUTH_TOKEN}`,
          },
        }
      );
      if (response.data) {
        // Add the `used_for` field with specific values based on iteration
        const updatedTemplates = response.data.data.map((template, index) => {
          let usedForValue;
          if (template.name === "hall_booking") {
            usedForValue = 'Hall Booking';
          } else if (template.name === "event_registration") {
            usedForValue = 'Event Registration';
          } else if (template.name === "opt_in_template") {
            usedForValue = 'Whatsapp Opt-in';
          } else {
            usedForValue = 'Not Allocated';
          }
          // if (index === 1) {
          //   usedForValue = 'Hall Booking';
          // } else if (index === 0) {
          //   usedForValue = 'Event Registration';
          // } else {
          //   usedForValue = 'Not Allocated';
          // }

          return {
            ...template,
            used_for: usedForValue,
          };
        });
        setTemplates(updatedTemplates);
        enqueueSnackbar('Templates fetched successfully!', { variant: 'success' });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar('Failed to fetch templates.', { variant: 'error' });
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAllTemplates();
  }, []);

  const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    {
      Header: 'Status', accessor: 'status', align: "center",
      Cell: ({ row }: { row: Row['row'] }) => (
        <div>
          <MDBadge
            {...({} as any)}
            color="dark"
            variant="gradient"
            size="xs"
            badgeContent={row.original.status}
            circular={false}
            indicator={false}
            border={false}
            container={false}
          />
        </div>
      ),
    },
    { Header: 'Used For', accessor: 'used_for' },
    {
      Header: 'Header Text',
      accessor: 'headerText',
      Cell: ({ row }: { row: { original: Template } }) =>
        row.original.components.find(c => c.type === 'HEADER')?.text || ''
    },
    {
      Header: 'Body Text',
      accessor: 'bodyText',
      Cell: ({ row }: { row: { original: Template } }) =>
        row.original.components.find(c => c.type === 'BODY')?.text || ''
    },
    {
      Header: 'Footer Text',
      accessor: 'footerText',
      Cell: ({ row }: { row: { original: Template } }) =>
        row.original.components.find(c => c.type === 'FOOTER')?.text || ''
    },
    { Header: 'Category', accessor: 'category' },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: { row: { original: Template } }) => (
        <div>
          <IconButton
            color="primary"
            aria-label="edit"
            onClick={() => handleTemplateClick(row.original)}
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Watermark
        height={256}
        width={256}
        // height={512}
        // width={512}
        image={Chamber_SaaS_Logo_Transparent}
        zIndex={-1}
        gap={[1000, 1000]}
      // style={{opacity:"0.5"}}
      > */}
      <Box pt={6} pb={3} style={{height:"82vh"}}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${Chamber_SaaS_Logo_Transparent})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.7, // Set opacity for background image
            zIndex: -1 // Ensure background is behind the content
          }}
        />
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={3}
                py={2}
              >
                <Typography
                  variant="h6"
                  color="black"
                  sx={{ ml: 1, mb: 2 }}
                >
                  Templates
                </Typography>
                {/* <Box className="Chamber_btnsHeaderCont">
                  <Button 
                  onClick={() => navigate('/template/add')} 
                  variant="outlined" 
                  sx={{ mb: 2, mr: 1, color: "#000", border: "1px black solid", "&:hover": { color: "#6c5ce7" ,border: "2px #6c5ce7 solid"}}}>
                    Add Templates
                  </Button>
                </Box> */}
              </Box>
              <Box pt={3}>
                {templates && (
                  <DataTable
                    table={{ columns, rows: templates }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={templateDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Enter your custom template details</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Header Text"
            fullWidth
            value={headerText}
            onChange={(e) => setHeaderText(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Body Text"
            fullWidth
            value={bodyText}
            onChange={(e) => setBodyText(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Footer Text"
            fullWidth
            value={footerText}
            onChange={(e) => setFooterText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDialogSubmit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {/* </Watermark> */}
    </DashboardLayout>
  );
};

export default Templates;
