/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
// Material Dashboard 2 React example components
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Watermark } from "antd";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Chamber_SaaS_Logo_Transparent from "../../../assets/Chamber_SaaS_Logo_Transparent.png";
import Grey_dark from "../../../assets/Chamber_SaaS_Logo_Grey_Dark.png";
import Grey_light from "../../../assets/Chamber_SaaS_Logo_Grey_Light.png";
import dayjs from "dayjs";

interface FormDataKeys {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  password: string;
  gst_number: string;
  contact_person: string;
  contact_number: string;
  address: string;
  pincode: string;
  city: string;
  state: string;
  profit?: string;
  merchant_key?: string;
  organization_name: string;
  organization_category: string;
  subscription_from: string;
  subscription_till: string;
  status: string;
  is_national_chamber?: boolean;
  parent_id?: string;
  is_aggregator?: boolean;
  aggregator?: string;
}

interface FileState {
  certificate: File | null;
  cin: File | null;
  pan: File | null;
  logo: File | null;
  [key: string]: File | null;
}

interface Chamber {
  id: number;
  name: string;
}

function Chamberfunction() {
  const { state } = useLocation();
  const { type, chamber_id } = state || { type: "", chamber_id: "" };
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");
  const { enqueueSnackbar } = useSnackbar();
  const isEdit = type === "edit";
  const [isPasswordDialogOpen, setPasswordDialogOpen] =
    useState<boolean>(false);
  const [subscriptionStartDate, setSubscriptionStartDate] =
    useState<string>("");
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>("");
  const [selectedServices, setSelectedServices] = useState<{
    [key: string]: boolean;
  }>({});
  const [servicesFromApi, setServicesFromApi] = useState<any>([]);
  const [isNationalChamber, setIsNationalChamber] = React.useState(false);
  const [isAggregator, setIsAggregator] = React.useState(false);
  const [chambers, setChambers] = useState<Chamber[]>([]);
  const [aggregator, setAggregator] = useState<Chamber[]>([]);
  const [selectedChamber, setSelectedChamber] = useState("");
  const [selectedAggregator, setSelectedAggregator] = useState("");
  const [isPartOfNationalChamber, setIsPartOfNationalChamber] = useState(false);
  const [isPartOfAggregator, setIsPartOfAggregator] = useState(false);
  const [formData, setFormData] = useState<FormDataKeys>({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    gst_number: "",
    contact_person: "",
    contact_number: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    profit: "",
    merchant_key: "",
    organization_name: "",
    organization_category: "",
    subscription_from: "",
    subscription_till: "",
    status: "",
  });

  const handleInputChange = (
    name: keyof FormDataKeys,
    value: string | boolean
  ) => {
    if (name === "subscription_from") {
      setSubscriptionStartDate(value as string);
      // If end date is already set and less than the new start date, reset it
      if (
        subscriptionEndDate &&
        dayjs(value as string).isAfter(dayjs(subscriptionEndDate))
      ) {
        setSubscriptionEndDate("");
        enqueueSnackbar("End date cannot be before start date", {
          variant: "error",
        });
      }
    } else if (name === "subscription_till") {
      // Check if end date is less than start date
      if (
        subscriptionStartDate &&
        dayjs(value as string).isBefore(dayjs(subscriptionStartDate))
      ) {
        enqueueSnackbar("End date must be greater than start date", {
          variant: "error",
        });
        return;
      }
      setSubscriptionEndDate(value as string);
    } else if (name === "is_national_chamber") {
      setIsNationalChamber(value as boolean);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [fileState, setFileState] = useState<FileState>({
    certificate: null,
    cin: null,
    pan: null,
    logo: null,
  });

  const onSelectFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof FileState
  ) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;

    if (selectedFile) {
      setFileState((prevFileState) => ({
        ...prevFileState,
        [key]: selectedFile,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      const requiredFields = [
        "first_name",
        "last_name",
        "phone_number",
        "email",
        "password",
        "organization_name",
        "organization_category",
        "contact_person",
        "contact_number",
        ];
      const missingFields = requiredFields.filter(
        (field) => !formData[field as keyof FormDataKeys]
      );

      if (missingFields.length > 0) {
        enqueueSnackbar(`Please fill in all fields`, { variant: "error" });
        return;
      }

      // const requiredFiles = ["certificate", "cin", "pan", "logo"];
      // const missingFiles = requiredFiles.filter((file) => !fileState[file]);

      // if (missingFiles.length > 0) {
      //   enqueueSnackbar(`Please upload the ${missingFiles.join(", ")} file`, {
      //     variant: "error",
      //   });
      //   return;
      // }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        enqueueSnackbar("Please enter a valid email address", {
          variant: "error",
        });
        return;
      }

      if (formData.phone_number.length !== 10) {
        enqueueSnackbar("Mobile number must be 10 digits", {
          variant: "error",
        });
        return;
      }

      const formDataObj = new FormData();

      // Add form data fields
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          const value = formData[key as keyof FormDataKeys];
          if (value !== undefined) {
            const valueToAppend =
              typeof value === "boolean" ? value.toString() : value;
            formDataObj.append(key, valueToAppend as string | Blob);
          }
        }
      }

      // Add files
      for (const key in fileState) {
        const value = fileState[key];
        if (value !== null) {
          formDataObj.append(key, value);
        }
      }

      formDataObj.append("is_national_chamber", isNationalChamber.toString());
      if (!isAggregator && selectedAggregator) {
        formDataObj.append("aggregator_id", selectedAggregator);
        formDataObj.append("is_aggregator", "false");
      }

      if (!isNationalChamber && selectedChamber) {
        formDataObj.append("parent_id", selectedChamber);
        formDataObj.append("is_national_chamber", "false");
      } else if (isNationalChamber) {
        formDataObj.append("is_national_chamber", "true");
      }

      if (!isNationalChamber) {
        const selectedChamberId = selectedChamber;
        if (selectedChamberId) {
          formDataObj.append("parent_id", selectedChamberId);
        }
      }

      if (
        isNationalChamber ||
        isAggregator ||
        (!selectedAggregator && !selectedChamber)
      ) {
        if (!formData.merchant_key) {
          enqueueSnackbar("Payu Merchant key should not be empty", {
            variant: "error",
          });
          return;
        }
      }

      const selectedServiceIds = Object.keys(selectedServices)
        .filter((serviceId) => selectedServices[serviceId])
        .map((serviceId) => parseInt(serviceId))
        .filter((serviceId) => !isNaN(serviceId));

      const selectedServiceIdsString = JSON.stringify(selectedServiceIds);
      formDataObj.append("services", `"${selectedServiceIdsString}"`);

      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/chamber`,
        formDataObj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (res.data.success) {
        enqueueSnackbar("Chamber created successfully", { variant: "success" });
        navigate("/chamber");
      } else {
        enqueueSnackbar("Failed to create chamber", { variant: "error" });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Error occurred while creating the chamber. Please try again.",
        { variant: "error" }
      );
    }
  };
  
  const handlePartOfNationalChamberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPartOfNationalChamber(event.target.checked);
    if (event.target.checked) {
      setSelectedChamber("");
    }
  };

  const handlePartOfAggregatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPartOfAggregator(event.target.checked);
    if (event.target.checked) {
      setSelectedAggregator("");
    }
  };

  const handleUpdate = async () => {
    try {
      const requiredFields = [
        "first_name",
        "last_name",
        "phone_number",
        "email",
        "organization_name",
        "organization_category",
        "contact_person",
        "contact_number",
      ];

      const isAnyRequiredFieldEmpty = requiredFields.some((field) => {
        const value = formData[field as keyof typeof formData];
        return typeof value === "string" ? value.trim() === "" : !value;
      });

      if (isAnyRequiredFieldEmpty) {
        enqueueSnackbar("Please fill in all required fields", { variant: "error" });
        return;
      }

      const formDataObj = new FormData();

      for (const key in formData) {
        if (formData.hasOwnProperty(key) && key !== "password") {
          const value = formData[key as keyof typeof formData];
          if (value !== undefined) {
            const valueToAppend = typeof value === "boolean" ? value.toString() : value;
            formDataObj.append(key, valueToAppend as string | Blob);
          }
        }
      }

      for (const key in fileState) {
        const value = fileState[key];
        if (value !== null) {
          formDataObj.append(key, value);
        }
      }

      if (isAggregator) {
        formDataObj.append("is_aggregator", "true");
      } else if (selectedAggregator) {
        formDataObj.append("aggregator_id", selectedAggregator);
        formDataObj.append("is_aggregator", "false");
      }

      if (!isNationalChamber && selectedChamber) {
        formDataObj.append("parent_id", selectedChamber);
        formDataObj.append("is_national_chamber", "false");
      } else if (isNationalChamber) {
        formDataObj.append("is_national_chamber", "true");
      }

      if (
        isNationalChamber ||
        isAggregator ||
        (!selectedAggregator && !selectedChamber)
      ) {
        if (!formData.merchant_key) {
          enqueueSnackbar("Payu Merchant key should not be empty", {
            variant: "error",
          });
          return;
        }
      }

      const selectedServiceIds = Object.keys(selectedServices)
        .filter((serviceId) => selectedServices[serviceId])
        .map((serviceId) => parseInt(serviceId))
        .filter((serviceId) => !isNaN(serviceId));

      formDataObj.append("services", JSON.stringify(selectedServiceIds));

      const res = await axios.put(
        `${process.env.REACT_APP_BASEURL}/chamber/${chamber_id}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (res.data.success) {
        enqueueSnackbar("Chamber updated successfully", { variant: "success" });
        navigate("/chamber");
      } else {
        enqueueSnackbar("Failed to update chamber", { variant: "error" });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Error occurred while updating the chamber. Please try again.",
        { variant: "error" }
      );
    }
  };

  const [passwordChangeData, setPasswordChangeData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handlePasswordChange = async () => {
    try {
      const { oldPassword, newPassword, confirmNewPassword } =
        passwordChangeData;

      if (!oldPassword || !newPassword || !confirmNewPassword) {
        enqueueSnackbar("Please fill in all password fields", {
          variant: "error",
        });
        return;
      }

      if (newPassword !== confirmNewPassword) {
        enqueueSnackbar("New password and confirm new password do not match", {
          variant: "error",
        });
        return;
      }

      const payload = {
        email: formData.email,
        current_password: oldPassword,
        new_password: newPassword,
      };

      const res = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/chamber/updatepassword`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (res.data.success) {
        enqueueSnackbar("Password updated successfully", {
          variant: "success",
        });
        navigate("/chamber");
      } else {
        enqueueSnackbar("Failed to update password. Please try again.", {
          variant: "error",
        });
      }

      setPasswordDialogOpen(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Error occurred while updating password. Please try again.",
        { variant: "error" }
      );
    }
  };

  const handlePasswordInputChange = (name: string, value: string) => {
    setPasswordChangeData({
      ...passwordChangeData,
      [name]: value,
    });
  };

  const formatDateForInput = (isoDate: string | null | undefined): string => {
    if (!isoDate) return "";

    return dayjs(isoDate).format("YYYY-MM-DD");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!chamber_id) {
          return;
        }

        const res = await axios.get(
          `${process.env.REACT_APP_BASEURL}/chamber/${chamber_id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const chamberData = res.data;

        setIsNationalChamber(chamberData.data.is_national_chamber || false);
        setIsAggregator(chamberData.data.is_aggregator || false);
        const formattedStartDate = formatDateForInput(
          chamberData.data.subscription_from
        );
        setSubscriptionStartDate(formattedStartDate);
        const formattedEndDate = formatDateForInput(
          chamberData.data.subscription_till
        );
        setSubscriptionEndDate(formattedEndDate);

        const updatedFormData = {
          first_name: chamberData.data.first_name || "",
          last_name: chamberData.data.last_name || "",
          phone_number: chamberData.data.phone_number || "",
          email: chamberData.data.email || "",
          password: chamberData.data.password || "",
          gst_number: chamberData.data.gst_number || "",
          contact_person: chamberData.data.contact_person || "",
          contact_number: chamberData.data.contact_number || "",
          address: chamberData.data.address || "",
          pincode: chamberData.data.pincode || "",
          city: chamberData.data.city || "",
          state: chamberData.data.state || "",
          profit: chamberData.data.profit || "",
          merchant_key: chamberData.data.merchant_key || "",
          organization_name: chamberData.data.organization_name || "",
          organization_category: chamberData.data.organization_category || "",
          subscription_from: chamberData.data.subscription_from || "",
          subscription_till: chamberData.data.subscription_till || "",
          status: chamberData.data.status || "test",
        };
        setFormData(updatedFormData);

        const updatedFileState = {
          certificate: chamberData.data.gst_certificate || null,
          cin: chamberData.data.cin || null,
          pan: chamberData.data.pan || null,
          logo: chamberData.data.logo || null,
        };
        setFileState(updatedFileState);

        const selectedServicesFromApi: number[] =
          chamberData.data.services || [];
        const updatedSelectedServices: { [key: string]: boolean } = {
          ...selectedServices,
        };
        selectedServicesFromApi.forEach((serviceId: number) => {
          updatedSelectedServices[serviceId.toString()] = true;
        });
        setSelectedServices(updatedSelectedServices);

        if (chamberData.data.parent_id) {
          setSelectedChamber(chamberData.data.parent_id);
        } else {
          setSelectedChamber("");
        }

        if (chamberData.data.aggregator_id) {
          setSelectedAggregator(chamberData.data.aggregator_id);
        } else {
          setSelectedAggregator("");
        }
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Error fetching chamber data", { variant: "error" });
      }
    };

    fetchData();
    fetchAvailableServices();
    fetchNationalChamber();
    fetchAggregators();
  }, [chamber_id, enqueueSnackbar]);

  const BASE_URL = "https://pixa-chambers-bucket.s3.ap-south-1.amazonaws.com";

  const handleView = (key: any) => {
    const documentURL = `${BASE_URL}/${fileState[key]}`;
    window.open(documentURL, "_blank");
  };

  const handleDownload = (key: any) => {
    const documentURL = `${BASE_URL}/${fileState[key]}`;

    fetch(documentURL)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, `${key}`);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        enqueueSnackbar("Error downloading file", { variant: "error" });
      });
  };

  const fetchAvailableServices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/service`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const services = response.data.data;
      const formattedServices = services.reduce((acc: any, service: any) => {
        acc[service.service_id] = service.name;
        return acc;
      }, {});
      setServicesFromApi(formattedServices);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const handleServiceCheckboxChange = (serviceId: any) => {
    setSelectedServices((prevServices) => ({
      ...prevServices,
      [serviceId]: !prevServices[serviceId],
    }));
  };

  const fetchNationalChamber = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/chamber/getallnationalchambers`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (res.data.success && Array.isArray(res.data.data)) {
        setChambers(res.data.data);
      } else {
        enqueueSnackbar("Unexpected response format", { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching national chambers:", error);
      enqueueSnackbar("Error fetching national chambers", { variant: "error" });
    }
  };

  const fetchAggregators = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/chamber/getallaggregatorchambers`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (res.data.success && Array.isArray(res.data.data)) {
        setAggregator(res.data.data);
      } else {
        enqueueSnackbar("Unexpected response format", { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching aggregators:", error);
      enqueueSnackbar("Error fetching aggregators", { variant: "error" });
    }
  };

  const handleNationalChamberChange = (event: any) => {
    setIsNationalChamber(event.target.checked);
    if (event.target.checked) {
      setSelectedChamber("");
    }
  };

  const handleAggregatorChange = (event: any) => {
    setIsAggregator(event.target.checked);
    if (event.target.checked) {
      setSelectedChamber("");
    }
  };

  const handleChamberChange = (event: any) => {
    setSelectedChamber(event.target.value);
  };

  const handleAggregatorChamberChange = (event: any) => {
    setSelectedAggregator(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Watermark
        height={256}
        width={256}
        // height={512}
        // width={512}
        image={Grey_dark}
        zIndex={-1}
      // style={{opacity:"0.5"}}
      > */}
      <Box pt={6} pb={3}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${Chamber_SaaS_Logo_Transparent})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: 0.7, // Set opacity for background image
            zIndex: -1, // Ensure background is behind the content
          }}
        />
        <Grid container spacing={3} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography variant="h6" color="black" sx={{ ml: 1, mb: -2 }}>
                    Add Chamber
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={3} justifyContent="flex-start" p={4}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    required
                    value={formData.first_name}
                    onChange={(e) =>
                      handleInputChange("first_name", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    required
                    value={formData.last_name}
                    onChange={(e) =>
                      handleInputChange("last_name", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    required
                    value={formData.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    required
                    value={formData.phone_number}
                    onChange={(e) =>
                      handleInputChange("phone_number", e.target.value)
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: isEdit ? "90%" : "100%",
                      marginRight: isEdit ? "10px" : "0",
                    }}
                  >
                    <TextField
                      type="password"
                      label="Password"
                      name=""
                      margin="dense"
                      fullWidth
                      required
                      onChange={(e) =>
                        handleInputChange("password", e.target.value)
                      }
                      value={formData.password}
                    />
                  </div>
                  {isEdit && (
                    <IconButton
                      color="info"
                      onClick={() => setPasswordDialogOpen(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="GST Number"
                    variant="outlined"
                    value={formData.gst_number}
                    onChange={(e) =>
                      handleInputChange("gst_number", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Contact Person"
                    variant="outlined"
                    required
                    value={formData.contact_person}
                    onChange={(e) =>
                      handleInputChange("contact_person", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Contact Number"
                    variant="outlined"
                    required
                    value={formData.contact_number}
                    onChange={(e) =>
                      handleInputChange("contact_number", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Address"
                    variant="outlined"
                    value={formData.address}
                    onChange={(e) =>
                      handleInputChange("address", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Pincode"
                    variant="outlined"
                    value={formData.pincode}
                    onChange={(e) =>
                      handleInputChange("pincode", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="City"
                    variant="outlined"
                    value={formData.city}
                    onChange={(e) => handleInputChange("city", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="State"
                    variant="outlined"
                    value={formData.state}
                    onChange={(e) => handleInputChange("state", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Profit (%)"
                    variant="outlined"
                    value={formData.profit}
                    onChange={(e) =>
                      handleInputChange("profit", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Payu Merchant Key"
                    variant="outlined"
                    value={formData.merchant_key}
                    onChange={(e) =>
                      handleInputChange("merchant_key", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Organization Name"
                    variant="outlined"
                    required
                    value={formData.organization_name}
                    onChange={(e) =>
                      handleInputChange("organization_name", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Organization Category"
                    variant="outlined"
                    required
                    value={formData.organization_category}
                    onChange={(e) =>
                      handleInputChange("organization_category", e.target.value)
                    }
                  />
                </Grid>
                <Box mb={10}></Box>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Typography
                      variant="h6"
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                    >
                      Select Services
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                  >
                    {Object.entries(servicesFromApi).map(
                      ([serviceId, serviceName]) => (
                        <Grid item key={serviceId}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedServices[serviceId]}
                                onChange={() =>
                                  handleServiceCheckboxChange(serviceId)
                                }
                              />
                            }
                            label={serviceName as any}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="flex-start" p={3}>
                  <Grid container spacing={3} justifyContent="flex-start" p={3}>
                    {/* National Chamber Checkbox */}
                    {!isAggregator && (
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          sx={{
                            marginTop: "45px",
                            marginLeft: "40px",
                          }}
                          control={
                            <Checkbox
                              checked={isNationalChamber}
                              onChange={handleNationalChamberChange}
                              name="is_national_chamber"
                            />
                          }
                          label="Are you a national chamber?"
                        />
                      </Grid>
                    )}

                    {/* Are you a part of a National Chamber? */}
                    {!isNationalChamber && !isAggregator && (
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          sx={{ marginTop: "45px", marginLeft: "40px" }}
                          control={
                            <Checkbox
                              checked={isPartOfNationalChamber || !!selectedChamber}
                              onChange={handlePartOfNationalChamberChange}
                              name="is_part_of_national_chamber"
                            />
                          }
                          label="Are you a part of a national chamber?"
                        />
                        {(isPartOfNationalChamber || !!selectedChamber) && (
                          <FormControl fullWidth sx={{ marginLeft: "20px", marginTop: "10px" }}>
                            <Select
                              labelId="national-chamber-label"
                              value={selectedChamber}
                              onChange={handleChamberChange}
                              sx={{
                                height: "45px",
                                "& .MuiSelect-select": {
                                  padding: "14px 10px",
                                },
                              }}
                            >
                              {chambers.map((chamber: any) => (
                                <MenuItem key={chamber.chamber_id} value={chamber.chamber_id}>
                                  {chamber.first_name} {chamber.last_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                    )}

                    {/* Aggregator Checkbox */}
                    {!isNationalChamber && (
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          sx={{
                            marginTop: "45px",
                            marginLeft: "40px",
                          }}
                          control={
                            <Checkbox
                              checked={isAggregator}
                              onChange={handleAggregatorChange}
                              name="is_aggregator"
                            />
                          }
                          label="Are you an aggregator?"
                        />
                      </Grid>
                    )}

                    {/* Are you a part of an Aggregator? */}
                    {!isAggregator && !isNationalChamber && (
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          sx={{ marginTop: "45px", marginLeft: "40px" }}
                          control={
                            <Checkbox
                              checked={isPartOfAggregator || !!selectedAggregator} // Checkbox remains checked if selectedAggregator exists
                              onChange={handlePartOfAggregatorChange}
                              name="is_part_of_aggregator"
                            />
                          }
                          label="Are you a part of an aggregator?"
                        />
                        {(isPartOfAggregator || !!selectedAggregator) && (
                          <FormControl fullWidth sx={{ marginLeft: "20px", marginTop: "10px" }}>
                            <Select
                              labelId="aggregator-label"
                              value={selectedAggregator}
                              onChange={handleAggregatorChamberChange}
                              sx={{
                                height: "45px",
                                "& .MuiSelect-select": {
                                  padding: "14px 10px",
                                },
                              }}
                            >
                              {aggregator.map((aggregator: any) => (
                                <MenuItem
                                  key={aggregator.chamber_id}
                                  value={aggregator.chamber_id}
                                >
                                  {aggregator.first_name} {aggregator.last_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="flex-start" p={3}>
                  <Grid item xs={12} md={6}>
                    <label style={{ marginLeft: "20px" }}>
                      <Typography
                        variant="h6"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                      >
                        Subscription start
                      </Typography>
                      <TextField
                        type="date"
                        name=""
                        margin="dense"
                        fullWidth
                        required
                        onChange={(e) =>
                          handleInputChange("subscription_from", e.target.value)
                        }
                        value={subscriptionStartDate}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* Subscription End */}
                    <label style={{ marginLeft: "20px" }}>
                      <Typography
                        variant="h6"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                      >
                        Subscription end
                      </Typography>
                      <TextField
                        type="date"
                        name=""
                        margin="dense"
                        fullWidth
                        required
                        onChange={(e) =>
                          handleInputChange("subscription_till", e.target.value)
                        }
                        value={subscriptionEndDate}
                      />
                    </label>
                  </Grid>
                </Grid>
                <Box mb={3}></Box>
                <Grid container spacing={2} alignItems="center">
                  {['GST certificate', 'cin', 'pan', 'logo'].map((key) => (
                    <Grid item xs={12} md={6} key={key}>
                      <label htmlFor={key} style={{ marginLeft: "20px" }}>
                        <Typography
                          variant="h6"
                          style={{
                            marginBottom: "10px",
                            marginLeft: "25px",
                            textTransform: "capitalize",
                          }}
                        >
                          {key === "cin" || key === "pan" || key === "logo"
                            ? `Update ${key.toUpperCase()}`
                            : `Update ${key.charAt(0).toUpperCase() + key.slice(1)
                            }`}
                        </Typography>
                        <input
                          id={key}
                          type="file"
                          style={{ marginBottom: "10px", marginLeft: "25px" }}
                          onChange={(e) => onSelectFile(e, key)}
                        />
                      </label>
                      {isEdit && fileState[key] && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#6c5ce7",
                              marginBottom: "10px",
                              marginLeft: "25px",
                              color: "white",
                            }}
                            onClick={() => handleView(key)}
                          >
                            View
                          </Button>
                          <Button
                            variant="contained"
                            color="info"
                            style={{ marginLeft: "25px" }}
                            onClick={() => handleDownload(key)}
                          >
                            Download
                          </Button>
                        </div>
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  p={2}
                  pb={0.5}
                >
                  <Button
                    onClick={isEdit ? handleUpdate : handleSubmit}
                    variant="contained"
                    style={{ color: "white", backgroundColor: "#6c5ce7" }}
                  >
                    {isEdit ? "Update Chamber" : "Create Chamber"}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={isPasswordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
      >
        <DialogTitle>Password Change</DialogTitle>
        <DialogContent>
          <TextField
            type="password"
            label="Old Password"
            name=""
            margin="dense"
            fullWidth
            required
            onChange={(e) =>
              handlePasswordInputChange("oldPassword", e.target.value)
            }
            value={passwordChangeData.oldPassword}
          />
          <TextField
            type="password"
            label="New Password"
            name=""
            margin="dense"
            fullWidth
            required
            onChange={(e) =>
              handlePasswordInputChange("newPassword", e.target.value)
            }
            value={passwordChangeData.newPassword}
          />
          <TextField
            type="password"
            label="Confirm New Password"
            name=""
            margin="dense"
            fullWidth
            required
            onChange={(e) =>
              handlePasswordInputChange("confirmNewPassword", e.target.value)
            }
            value={passwordChangeData.confirmNewPassword}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPasswordDialogOpen(false)}
            variant="contained"
            color="error"
            style={{ color: "black" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handlePasswordChange}
            variant="contained"
            color="primary"
            style={{ color: "white" }}
          >
            Update Password
          </Button>
        </DialogActions>
      </Dialog>
      {/* </Watermark> */}
    </DashboardLayout>
  );
}

export default Chamberfunction;